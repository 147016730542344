import { QuickAbdest } from "./components/pages/Abdest";
import { AbdestBozan } from "./components/pages/AbdestBozan";
import { AbdestEngelli } from "./components/pages/AbdestEngelli";
import { AbdestEtimiloji } from "./components/pages/AbdestEtimoloji";
import { AbdestFarz } from "./components/pages/AbdestFarz";
import { AbdestHakkinda } from "./components/pages/AbdestHakkında";
import { AbdestHukum } from "./components/pages/AbdestHükümleri";
import { AbdestMekruh } from "./components/pages/AbdestMekruh";
import { AbdestNasil } from "./components/pages/AbdestNasılAlınır";
import { AbdestSunnet } from "./components/pages/AbdestSunnet";
import { AbdestTeyemmum } from "./components/pages/AbdestTeyemmüm";

export const routes = [
  {
    path: "/",
    children: [
      { path: "", element: <QuickAbdest /> },
      { path: "abdesthakkinda", element: <AbdestHakkinda /> },
      { path: "abdestnasilalinir", element: <AbdestNasil /> },
      { path: "abdestetimoloji", element: <AbdestEtimiloji /> },
      { path: "abdestinhukumleri", element: <AbdestHukum /> },
      { path: "abdestinfarzlari", element: <AbdestFarz /> },
      { path: "abdestinsunnetleri", element: <AbdestSunnet /> },
      { path: "abdestinmehruklari", element: <AbdestMekruh /> },
      { path: "abdestbozandurumlar", element: <AbdestBozan /> },
      { path: "engelliabdesti", element: <AbdestEngelli /> },
      { path: "teyemmumabdesti", element: <AbdestTeyemmum /> },
    ],
  },
];
