export function AbdestEngelli() {
  return (
    <>
      <h1>Engelli abdesti</h1>
      <p>
        Normal abdest almasına engel bir durumu olan Müslüman'ın, rahatsızlığına
        göre farklı yönlerden eksik kalan abdesttir.
      </p>
      <p>
        Vücudunun belli yerlerini tıbbi sebeplerden yıkayamayan insanlar normal
        abdest almaktan muhaf tutulur. Örnek olarak, kolu kırılan ve alçıya
        alınan kişi abdestini alır; ancak kolunu yıkayamadığından sadece alçının
        üstünü suyla mesh edebilir. O da zararlıysa, onu da yapmaz. Vücudunda
        devamlı kanayan yara olan Müslümanlar da engelli abdesti alır. Buna
        göre, normal abdest alırlar, fakat bu abdestle sadece tek vakit namaz
        kılabilirler. Engelli abdesti alan Müslüman'ın, tam abdest alan
        Müslüman'a cemaatle kılınan namazlarda imamlık yapması uygun
        görülmemiştir.
      </p>
    </>
  );
}
