export function AbdestFarz() {
  return (
    <>
      <h1>Abdestin Farzları</h1>
      <p>Sünni alimlere göre abdestin farzları dörttür:</p>
      <ul>
        <li>Başın dörtte birini meshetmek, yani ıslak elle sıvazlamak.</li>
        <li>Kolları (dirsekleriyle beraber) yıkamak.</li>
        <li>Yüzü yıkamak.</li>
        <li>Ayakları (topuklarıyla beraber) yıkamak.</li>
      </ul>
    </>
  );
}
