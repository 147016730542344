import Home from "./components/Home";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import createTheme from "./theme";
import "./App.css";
import useTheme from "./hooks/useTheme";

function App() {
  const { theme } = useTheme();
  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <Home />
    </MuiThemeProvider>
  );
}

export default App;
