export function AbdestMekruh() {
  return (
    <>
      <h1>Abdestin Mekruhları</h1>
      <ul>
        <li>Sağ el ile sümkürmek</li>
        <li>Abdest âzâlarından birini üç defadan az veya fazla yıkamak</li>
        <li>Suyu yüzüne çarpmak</li>
        <li>Güneşte ısınmış su ile abdest almak</li>
        <li>Suyu çok az kullanmak veya israf etmek</li>
        <li>Abdest alırken konuşmak</li>
        <li>Sünnetlerini terk etmek</li>
      </ul>
    </>
  );
}
