import "./Texts.css";

export function AbdestBozan() {
  return (
    <>
      <h1>Abdesti Bozan Durumlar</h1>
      <p>
        Nisa Suresi, 43 ayetine göre sekerat (şuuru yerinde olmamak:
        delilik/cinnet, esriklik/sarhoşluk, bayılmak-baygınlık, uyku-uyumak...)
        durumu ile boşaltım organlarından çıkış olması durumu namaza dolayısıyla
        da abdestin varlığına engeldir. Maide Suresi 6. ayetine göre namaz için
        abdest ya da teyemmüm şarttır.
      </p>
      <ul>
        <li>
          Boşaltım organlarından idrar, kan, meni, gaita (dışkı), yel gibi katı,
          sıvı veya gaz çıkması,
        </li>
        <li>
          Uyumak, delirmek, bayılmak, sarhoş olmak gibi idrak gücünün
          kaybedildiği durumlar,
        </li>
        <li>Kanama,</li>
        <li>Cinsî münasebet,</li>
        <li>Ağız dolusu kusmak,</li>
        <li>Teyemmüm almış birinin su bulması,</li>
        <li>Yellenmek.</li>
      </ul>
    </>
  );
}
