export function AbdestNasil() {
  return (
    <>
      <h1>Abdest Nasıl Alınır?</h1>
      <ol>
        <li>Eller bileklere kadar Üç kere oğusturularak yıkanır.</li>
        <li>
          Sağ elle, ilkin ağza, sonra buruna olmak üzere, üçer kere su alınır.
        </li>
        <li>Yüz, Üç kere yıkanır.</li>
        <li>
          Dirseği biraz aşacak şekilde önce sağ kol, sonra sol kol üçer kere
          yıkanır.
        </li>
        <li>
          Sağ elin içi ıslatılıp, alından enseye doğru sıvazlanarak baş mesh
          edilir.
        </li>
        <li>
          Islak elin başparmağıyla, serçeparmağıyla sıvazlanarak kulaklar mesh
          edilir.
        </li>
        <li>
          Islak parmakların tersiyle, ense ortasından yanlara doğru boyun da
          mesh edilir.
        </li>
        <li>
          Topuğu biraz geçmek üzere, sol elle ilkin sağ, sonra sol ayak yıkanır.
        </li>
      </ol>
      <h2>Engelli Abdesti Nasıl Alınır?</h2>
      <p>
        Normal abdest almasına engel bir durumu olan Müslüman'ın, rahatsızlığına
        göre farklı yönlerden eksik kalan abdesttir.
      </p>
      <p>
        Vücudunun belli yerlerini tıbbi sebeplerden yıkayamayan insanlar normal
        abdest almaktan muhaf tutulur. Örnek olarak, kolu kırılan ve alçıya
        alınan kişi abdestini alır; ancak kolunu yıkayamadığından sadece alçının
        üstünü suyla mesh edebilir. O da zararlıysa, onu da yapmaz. Vücudunda
        devamlı kanayan yara olan Müslümanlar da engelli abdesti alır. Buna
        göre, normal abdest alırlar, fakat bu abdestle sadece tek vakit namaz
        kılabilirler. Engelli abdesti alan Müslüman'ın, tam abdest alan
        Müslüman'a cemaatle kılınan namazlarda imamlık yapması uygun
        görülmemiştir.
      </p>
    </>
  );
}
