import { CssBaseline, Grid, Paper as MuiPaper } from "@mui/material";
import NavMenu from "./Menu.js";
import { useRoutes } from "react-router-dom/dist";
import { routes } from "../routes";
import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import "./pages/Texts.css";
// import Social from "./Social";

const Paper = styled(MuiPaper)(spacing);

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  margin: 20px 20px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
export default function Home() {
  const content = useRoutes(routes);
  return (
    <React.Fragment>
      <CssBaseline />
      <AppContent>
        <NavMenu />
        {/* <Social /> */}
        <Grid
          item
          sx={{
            marginLeft: { xs: 0, lg: 45 },
            marginRight: { xs: 0, lg: 45 },
            overflow: "hidden",
          }}>
          <MainContent>{content}</MainContent>
        </Grid>
      </AppContent>
    </React.Fragment>
  );
}
