export function AbdestSunnet() {
  return (
    <>
      <h1>Abdestin Sünnetleri</h1>
      <ul>
        <li>Niyet etmek</li>
        <li>Eûzü ve Besmele ile başlamak</li>
        <li>Evvela ellerini bileklerine kadar yıkamak</li>
        <li>Misvak kullanmak</li>
        <li>Bir âzâ kurumadan diğerini yıkamak</li>
        <li>Ağzına ve burnuna üç kere su vermek</li>
        <li>Kulağını meshetmek</li>
        <li>
          Parmaklarını hilâllemek; yâni bir elin parmaklarını diğer elin
          parmakları arasına geçirip çekmek
        </li>
        <li>Âzâları üçer kere yıkamak</li>
        <li>Başını kaplama meshetmek</li>
        <li>
          Abdesti tertip üzere almak; yâni abdest âzâlarını sırasıyla yıkamak
        </li>
        <li>El ve ayaklarını yıkamakta parmak uçlarından başlamak</li>
        <li>
          Abdest alırken okunacak birçok duâ olmakla beraber evlâ olan bütün
          âzâlarını yıkarken besmele çekip şehâdet getirmektir
        </li>
      </ul>
    </>
  );
}
