export function AbdestEtimiloji() {
  return (
    <>
      <h1>Abdestin Etimolojisi</h1>
      <p>
        &quot;Abdest&quot; sözcüğü Türkçeye Selçuklular zamanında Farsça'dan
        geçmiştir. Anlamı &quot;su tutmak&quot;tır. âb (su) ve dest (tutmak,
        kavramak) kelimelerinin birleşiminden oluşmuştur. İran ve bazı diğer
        Müslüman ülkeler ile İngilizce konuşan ülkelerde abdest yerine
        &quot;vudu&quot; kelimesi kullanılır.
      </p>
      <p>
        Abdest Kur'an' da gasil veya gusül olarak geçer. Bu sözcük, Arapça'da
        &quot;bir sıvıyı bir nesne üzerinden akıtmak, koku sürünmek&quot;
        anlamlarına gelir. Bazı hadisçiler ve fıkıhçılar vudû kelimesini abdest
        anlamında kullanmakta ve gusül kelimesini boy abdesti için
        kullanmaktadırlar. Kur'an'da boy abdesti için ıttıhar yani taharlanma
        (temizlik) sözcüğü kullanılmaktadır.
      </p>
    </>
  );
}
