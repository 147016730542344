import merge from "deepmerge";
import { green, grey } from "@mui/material/colors";
import { THEMES } from "../constants";

const customBlue = {
  50: "#e9ebf0",
  100: "#cbced4",
  200: "#a4a9b3",
  300: "#858b94",
  400: "#6a6f76",
  500: "#5c6066",
  600: "#3c3f43",
  700: "#24441c",
  800: "#1a1d24",
  900: "#0f131a ",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      paper: customBlue[700],
      contrastText: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#233044",
    header: {
      color: grey[200],
      background: "#233044",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#1E2A38",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[400],
      contrastText: "#FFF",
    },
    background: {
      default: customBlue[800],
      paper: customBlue[900],
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
});

const variants = [defaultVariant, darkVariant];

export default variants;

/**
 * @typedef {object} VariantType
 * @property {string} name
 * @property {object} palette
 * @property {MainContrastTextType} palette.primary
 * @property {MainContrastTextType} palette.secondary
 * @property {ColorBgType} palette.background
 * @property {ColorBgType} header
 * @property {object} header.search
 * @property {ColorBgType} header.indicator
 * @property {ColorBgType} footer
 * @property {ColorBgType} sidebar
 * @property {ColorBgType} sidebar.header
 * @property {ColorBgType} sidebar.footer
 * @property {ColorBgType} sidebar.badge
 */

/**
 * @typedef {object} MainContrastTextType
 * @property {string} main
 * @property {string} contrastText
 */

/**
 * @typedef {object} ColorBgType
 * @property {string} color
 * @property {string} background
 */
