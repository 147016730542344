import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Settings from "./Settings";
import { AppBar, Button, Grid, Toolbar } from "@mui/material";

const pages = [
  { name: "Abdest Hakkında", href: "/abdesthakkinda" },
  { name: "Abdest Nasıl Alınır?", href: "/abdestnasilalinir" },
  { name: "Abdestin Etimolojisi", href: "/abdestetimoloji" },
  { name: "Abdestin Hükümleri", href: "/abdestinhukumleri" },
  { name: "Abdestin Farzları", href: "/abdestinfarzlari" },
  { name: "Abdestin Sünnetleri", href: "/abdestinsunnetleri" },
  { name: "Abdestin Mehrukları", href: "/abdestinmehruklari" },
  { name: "Abdesti Bozan Durumlar", href: "/abdestbozandurumlar" },
  { name: "Engelli Abdesti Nasıl Alınır?", href: "/engelliabdesti" },
  {
    name: "Teyemmüm Abdesti Nasıl Alınır?",
    href: "/teyemmumabdesti",
  },
];

function NavMenu() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (href) => {
    setAnchorElNav(null);
    if (href) {
      navigate(href);
    }
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" sx={{ zIndex: 99 }}>
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Grid
              item
              width={300}
              textAlign={"center"}
              sx={{
                display: { xs: "none", lg: "block" },
              }}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}>
                quickabdest
              </Typography>
            </Grid>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit">
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", lg: "none" },
                }}>
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => handleCloseNavMenu(page.href)}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", lg: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".5rem",
                color: "inherit",
                textDecoration: "none",
              }}>
              quickabdest
            </Typography>
            <Box sx={{ flexGrow: 4, display: { xs: "none", lg: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => handleCloseNavMenu(page.href)}
                  sx={{ color: "white", display: "block" }}
                  noWrap>
                  {page.name}
                </Button>
              ))}
            </Box>
            <Settings />
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}
export default NavMenu;
