import feelsGood from "../../files/feels.png";
import feelsBad from "../../files/feelsbad.png";
import { useEffect, useState } from "react";
import abdestSound from "../../files/sound.m4a";
import { Grid, Typography } from "@mui/material";

function Abdest() {
  const [abdest, setAbdest] = useState(localStorage.getItem("cd"));
  const [timeLeft, setTimeLeft] = useState(localStorage.getItem("timeleft"));
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  var audio = new Audio(abdestSound);

  const changeAbdestState = () => {
    if (!abdest) {
      audio.play();
      setAbdest(true);
      const currentTime = new Date().getTime();
      setTimeLeft(currentTime + 300000);
      setMinutes(5);
      setSeconds(0);
    }
  };

  useEffect(() => {
    localStorage.setItem("cd", abdest);
    localStorage.setItem("timeleft", timeLeft);
  }, [abdest, timeLeft]);

  useEffect(() => {
    let timer;

    if (abdest) {
      const storedTimeLeft = localStorage.getItem("timeleft");
      const currentTime = new Date().getTime();
      if (storedTimeLeft && currentTime < storedTimeLeft) {
        const timeDifference = Math.floor(
          (storedTimeLeft - currentTime) / 1000
        );
        const updatedMinutes = Math.floor(timeDifference / 60);
        const updatedSeconds = timeDifference % 60;
        setMinutes(updatedMinutes);
        setSeconds(updatedSeconds);
        setTimeLeft(storedTimeLeft);
        timer = setInterval(() => {
          const currentTime = new Date().getTime();
          if (currentTime >= storedTimeLeft) {
            setAbdest(false);
            setTimeLeft(null);
            setMinutes(0);
            setSeconds(0);
            clearInterval(timer);
          } else {
            const timeDifference = Math.floor(
              (storedTimeLeft - currentTime) / 1000
            );
            const updatedMinutes = Math.floor(timeDifference / 60);
            const updatedSeconds = timeDifference % 60;
            setMinutes(updatedMinutes);
            setSeconds(updatedSeconds);
          }
        }, 1000);
      } else {
        setAbdest(false);
        setTimeLeft(null);
        setMinutes(0);
        setSeconds(0);
        localStorage.removeItem("cd");
        localStorage.removeItem("timeleft");
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [abdest]);

  return (
    <Grid container textAlign="center">
      <Grid item onClick={changeAbdestState} xs={12} mt={7}>
        <img
          src={abdest ? feelsGood : feelsBad}
          alt=""
          height={"auto"}
          style={{ width: "clamp(12.5rem, 45%, 50rem)" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        m={2}
        sx={{ display: abdest ? "none" : "block", fontSize: "2rem" }}>
        abdest almak için{" "}
        <Typography
          component="a"
          style={{ color: "red", fontSize: "2rem" }}
          onClick={changeAbdestState}>
          tıkla
        </Typography>
      </Grid>
      <Grid item xs={12} m={2}>
        <Typography
          component="h2"
          style={{ fontSize: "2rem" }}
          className={abdest ? "abdestTrue" : "abdestFalse"}>
          {abdest ? "ABDESTLENDİN" : "abdest almanın doğru adresi"}
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12} m={2}>
          {abdest && (
            <Typography component="h2" style={{ fontSize: "2rem" }}>
              Abdestin geçerlilik süresi:{" "}
              {minutes < 10 ? "0" + minutes : minutes}:
              {seconds < 10 ? "0" + seconds : seconds}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} m={2}>
          <Typography variant="h5">
            Site tamamen eğlence amacıyla hazırlanmıştır
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function QuickAbdest() {
  return (
    <>
      <Abdest />
    </>
  );
}
