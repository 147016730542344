export function AbdestHakkinda() {
  return (
    <>
      <h1>Abdest</h1>
      <p>
        Abdest, Müslümanların, namaz gibi belli ibadetleri yapabilmek için bir
        düzen içerisinde bazı organları yıkayıp bazılarını mesh etme yoluyla
        yaptıkları arınma ve temizliktir. Kur'ana göre her namazın yanında
        bedensel temizlenme amacıyla belli organları yıkamak (gasil) ve
        meshetmek (mesih) şeklinde anlatılır. Suyun abdeste uygun olması
        önemlidir. Su abdeste tadı, bulanıklığı ve kokusunun olağan olması ile
        uygun olmaktadır.
      </p>
      <h2>Etimolojisi</h2>
      <p>
        &quot;Abdest&quot; sözcüğü Türkçeye Selçuklular zamanında Farsça'dan
        geçmiştir. Anlamı &quot;su tutmak&quot;tır. âb (su) ve dest (tutmak,
        kavramak) kelimelerinin birleşiminden oluşmuştur. İran ve bazı diğer
        Müslüman ülkeler ile İngilizce konuşan ülkelerde abdest yerine
        &quot;vudu&quot; kelimesi kullanılır.
      </p>
      <p>
        Abdest Kur'an' da gasil veya gusül olarak geçer. Bu sözcük, Arapça'da
        &quot;bir sıvıyı bir nesne üzerinden akıtmak, koku sürünmek&quot;
        anlamlarına gelir. Bazı hadisçiler ve fıkıhçılar vudû kelimesini abdest
        anlamında kullanmakta ve gusül kelimesini boy abdesti için
        kullanmaktadırlar. Kur'an'da boy abdesti için ıttıhar yani taharlanma
        (temizlik) sözcüğü kullanılmaktadır.
      </p>
      <h2>Teyemmüm</h2>
      <p>
        Fakihlere (fıkıh alimi) göre namaz kılmak için abdest yerine bazı
        durumlarda teyemmüm yapılabilir. Ayrıca teyemmüm, hastalık, yolculuk, su
        bulmama/suyun olmaması veya erişilememesi gibi durumlarda boy abdesti
        yerine de yapılır. Kur'an'da teyemmüm yapmak için türâb (toprak) sözcüğü
        kullanılmaz, bunun yerine said sözcüğü kullanılır ki bu sözcük
        &quot;toz, toprak, taş vs.&quot; anlamına gelmektedir. Kullanıma uygun
        su bulunduğu zaman bozulmaktadır.
      </p>
      <h2>Abdest Ayeti</h2>
      <p>
        Kur'anda; (Maide Suresi, 6), (Nisa suresi, 43), (Müddessir suresi, 4-5),
        (Bakara suresi, 222), (Tevbe suresi, 108), (Vakıa suresi, 79)'da geçer.
      </p>
      <p>
        Ayetin ayakları anlatan “ercüleküm” kelimesinin okunuşu kıraat
        mezhepleri arasında ihtilaflı bir konudur. Kelime iki şekilde
        okunabilir; ercüleküm şeklinde okunduğunda abdest alırken ayakların
        yıkanması gerektiği anlaşılır, ercüliküm şeklinde okunmasında ise
        ayakların yıkanmıyacağı, sadece meshedileceği anlaşılır.
      </p>
      <p>
        Ayette kullanılan vücûhe küm (vecihleriniz) ibaresi, başın ön yanı için
        kullanılır, bu yüzden başta saçların döküldüğü ön kısım, yüz, boyun altı
        demektir. Baş sıvazlanırken, ayette sınır koymadığı için, baş adlı
        organın başın ön yanı dışındaki her yanı, kulaklar, ense, boyun
        sıvazlanır.
      </p>
      <h2>Abdestin hükümleri</h2>
      <p>
        Fıkıh alimleri, Kur'an ve Sünneti referans göstererek abdestin
        hükümlerini (farz, sünnet, mendup, müstehab, mekruh vs.) şunlar olarak
        belirtirler:
      </p>
      <h3>Abdestin farzları</h3>
      <p>Sünni alimlere göre abdestin farzları dörttür:</p>
      <ul>
        <li>Başın dörtte birini meshetmek, yani ıslak elle sıvazlamak.</li>
        <li>Kolları (dirsekleriyle beraber) yıkamak.</li>
        <li>Yüzü yıkamak.</li>
        <li>Ayakları (topuklarıyla beraber) yıkamak.</li>
      </ul>
      <p>
        Şia alimlerden bazıları &quot;ayaklara meshetmenin&quot; abdestin
        farzlarından olduğuna, bazı alimler meshin farz, yıkamanın sünnet
        olduğuna, diğer bir kısmı ise her iki uygulamadan birisini yerine
        getirmenin yeterli olacağına inanmışlardır.
      </p>
      <h3>Abdestin sünnetleri</h3>
      <ul>
        <li>Niyet etmek</li>
        <li>Eûzü ve Besmele ile başlamak</li>
        <li>Evvela ellerini bileklerine kadar yıkamak</li>
        <li>Misvak kullanmak</li>
        <li>Bir âzâ kurumadan diğerini yıkamak</li>
        <li>Ağzına ve burnuna üç kere su vermek</li>
        <li>Kulağını meshetmek</li>
        <li>
          Parmaklarını hilâllemek; yâni bir elin parmaklarını diğer elin
          parmakları arasına geçirip çekmek
        </li>
        <li>Âzâları üçer kere yıkamak</li>
        <li>Başını kaplama meshetmek</li>
        <li>
          Abdesti tertip üzere almak; yâni abdest âzâlarını sırasıyla yıkamak
        </li>
        <li>El ve ayaklarını yıkamakta parmak uçlarından başlamak</li>
        <li>
          Abdest alırken okunacak birçok duâ olmakla beraber evlâ olan bütün
          âzâlarını yıkarken besmele çekip şehâdet getirmektir
        </li>
      </ul>
      <h3>Abdestin mekruhları</h3>
      <ul>
        <li>Sağ el ile sümkürmek</li>
        <li>Abdest âzâlarından birini üç defadan az veya fazla yıkamak</li>
        <li>Suyu yüzüne çarpmak</li>
        <li>Güneşte ısınmış su ile abdest almak</li>
        <li>Suyu çok az kullanmak veya israf etmek</li>
        <li>Abdest alırken konuşmak</li>
        <li>Sünnetlerini terk etmek</li>
      </ul>
      <h3>Abdesti bozan durumlar</h3>
      <p>
        Nisa Suresi, 43 ayetine göre sekerat (şuuru yerinde olmamak:
        delilik/cinnet, esriklik/sarhoşluk, bayılmak-baygınlık, uyku-uyumak...)
        durumu ile boşaltım organlarından çıkış olması durumu namaza dolayısıyla
        da abdestin varlığına engeldir. Maide Suresi 6. ayetine göre namaz için
        abdest ya da teyemmüm şarttır.
      </p>
      <ul>
        <li>
          Boşaltım organlarından idrar, kan, meni, gaita (dışkı), yel gibi katı,
          sıvı veya gaz çıkması,
        </li>
        <li>
          Uyumak, delirmek, bayılmak, sarhoş olmak gibi idrak gücünün
          kaybedildiği durumlar,
        </li>
        <li>Kanama,</li>
        <li>Cinsî münasebet,</li>
        <li>Ağız dolusu kusmak,</li>
        <li>Teyemmüm almış birinin su bulması,</li>
        <li>Yellenmek.</li>
      </ul>
      <h3>Abdest nasıl alınır</h3>
      <ol>
        <li>Eller bileklere kadar Üç kere oğusturularak yıkanır.</li>
        <li>
          Sağ elle, ilkin ağza, sonra buruna olmak üzere, üçer kere su alınır.
        </li>
        <li>Yüz, Üç kere yıkanır.</li>
        <li>
          Dirseği biraz aşacak şekilde önce sağ kol, sonra sol kol üçer kere
          yıkanır.
        </li>
        <li>
          Sağ elin içi ıslatılıp, alından enseye doğru sıvazlanarak baş mesh
          edilir.
        </li>
        <li>
          Islak elin başparmağıyla, serçeparmağıyla sıvazlanarak kulaklar mesh
          edilir.
        </li>
        <li>
          Islak parmakların tersiyle, ense ortasından yanlara doğru boyun da
          mesh edilir.
        </li>
        <li>
          Topuğu biraz geçmek üzere, sol elle ilkin sağ, sonra sol ayak yıkanır.
        </li>
      </ol>
      <h3>Engelli abdesti</h3>
      <p>
        Normal abdest almasına engel bir durumu olan Müslüman'ın, rahatsızlığına
        göre farklı yönlerden eksik kalan abdesttir.
      </p>
      <p>
        Vücudunun belli yerlerini tıbbi sebeplerden yıkayamayan insanlar normal
        abdest almaktan muhaf tutulur. Örnek olarak, kolu kırılan ve alçıya
        alınan kişi abdestini alır; ancak kolunu yıkayamadığından sadece alçının
        üstünü suyla mesh edebilir. O da zararlıysa, onu da yapmaz. Vücudunda
        devamlı kanayan yara olan Müslümanlar da engelli abdesti alır. Buna
        göre, normal abdest alırlar, fakat bu abdestle sadece tek vakit namaz
        kılabilirler. Engelli abdesti alan Müslüman'ın, tam abdest alan
        Müslüman'a cemaatle kılınan namazlarda imamlık yapması uygun
        görülmemiştir.
      </p>
    </>
  );
}
