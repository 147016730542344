export function AbdestTeyemmum() {
  return (
    <>
      <h1>Teyemmüm</h1>
      <p>
        Fakihlere (fıkıh alimi) göre namaz kılmak için abdest yerine bazı
        durumlarda teyemmüm yapılabilir. Ayrıca teyemmüm, hastalık, yolculuk, su
        bulmama/suyun olmaması veya erişilememesi gibi durumlarda boy abdesti
        yerine de yapılır. Kur'an'da teyemmüm yapmak için türâb (toprak) sözcüğü
        kullanılmaz, bunun yerine said sözcüğü kullanılır ki bu sözcük
        &quot;toz, toprak, taş vs.&quot; anlamına gelmektedir. Kullanıma uygun
        su bulunduğu zaman bozulmaktadır.
      </p>
    </>
  );
}
