import React, { useEffect, useState, createContext } from "react";
import { THEMES } from "../constants";

const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: (theme) => {},
};

const ThemeContext = createContext(initialState);

function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(initialState.theme);

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");

    if (storedTheme) {
      setTheme(JSON.parse(storedTheme));
    }
  }, []);

  const updateTheme = (theme) => {
    localStorage.setItem("theme", JSON.stringify(theme));
    setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
