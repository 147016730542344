export function AbdestHukum() {
  return (
    <>
      <h1>Abdestin Hükümleri</h1>
      <p>
        Fıkıh alimleri, Kur'an ve Sünneti referans göstererek abdestin
        hükümlerini (farz, sünnet, mendup, müstehab, mekruh vs.) şunlar olarak
        belirtirler:
      </p>
      <h3>Abdestin farzları</h3>
      <p>Sünni alimlere göre abdestin farzları dörttür:</p>
      <ul>
        <li>Başın dörtte birini meshetmek, yani ıslak elle sıvazlamak.</li>
        <li>Kolları (dirsekleriyle beraber) yıkamak.</li>
        <li>Yüzü yıkamak.</li>
        <li>Ayakları (topuklarıyla beraber) yıkamak.</li>
      </ul>
      <p>
        Şia alimlerden bazıları &quot;ayaklara meshetmenin&quot; abdestin
        farzlarından olduğuna, bazı alimler meshin farz, yıkamanın sünnet
        olduğuna, diğer bir kısmı ise her iki uygulamadan birisini yerine
        getirmenin yeterli olacağına inanmışlardır.
      </p>
    </>
  );
}
